<template>
  <router-view/>
</template>

<script>

export default {
  watch: {
    '$route' (to) {
      const title = to.meta.title
      if (title) {
        document.title = title
      }
      const sharelink = document.querySelector('link[rel="canonical"]')
      sharelink.setAttribute('href', `https://www.papawash.com.tw${to.fullPath}`)
    }
  }
}
</script>
