import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate'
import { required, email, min, length } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import zhTW from '@vee-validate/i18n/dist/locale/zh_TW.json'

import SwiperCore, { Autoplay, Virtual, Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/swiper-bundle.min.css'

import App from './App.vue'
import store from './store'
import router from './router'
import 'bootstrap-icons/font/bootstrap-icons.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import { currency } from './assets/js/currency.js'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import '../src/assets/scss/helpers/_alert.scss'

// ?vue-gtag-next
import VueGtag from 'vue-gtag-next'

AOS.init({
  delay: 0,
  duration: 1500,
  easing: 'ease-out-back',
  offset: 200
})

// ?axios 設定
axios.interceptors.request.use(
  config => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
)
// ?設定cross跨域 並設定訪問許可權 允許跨域攜帶cookie資訊
axios.defaults.withCredentials = false

axios.interceptors.response.use(
  config => {
    return config
  },
  err => {
    // ?非 200 或非 401 的狀態顯示(ex. 500 時顯示)
    if (err.response && (err.response.status !== 200 && err.response.status !== 401)) {
      Swal.fire({
        title: `請重新整理頁面(${err.response.status})`,
        allowOutsideClick: true,
        confirmButtonColor: '#003894',
        confirmButtonText: '確認',
        backdrop: true,
        width: 400,
        customClass: {
          title: 'text-class',
          confirmButton: 'confirm-btn-class'
        }
      })
      return Promise.reject(err)
    }
    if (err.response && err.response.status === 401) {
      // ? refresh_token 重新拿新的一組token，並重打API
      const refreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (refreshToken) {
        const url = `${process.env.VUE_APP_API}/api/members/RefreshToken`
        const rftk = axios({
          method: 'get',
          url: url,
          headers: { Authorization: `Bearer ${refreshToken}` }
        })
          .then((res) => {
            store.dispatch('loginSetCookie', res.data.info)
            return Promise.reject(err)
          })
        return Promise.all([rftk])
      }
      store.commit('changeLoginState', false)
      store.commit('refreshloginState', 'login')
      Swal.fire({
        title: '請先登入',
        allowOutsideClick: false,
        confirmButtonColor: '#003894',
        confirmButtonText: '確認',
        backdrop: true,
        width: 400,
        customClass: {
          title: 'text-class',
          confirmButton: 'confirm-btn-class'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.commit('getcartNum', 0)
          router.push('/registerlogin')
        }
      })
    }
  }
)

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('length', length)
configure({
  generateMessage: localize({ zh_TW: zhTW }),
  validateOnInput: true
})
setLocale('zh_TW')

SwiperCore.use([Autoplay, Virtual, Navigation, Pagination, Scrollbar])

const app = createApp(App)
app.config.globalProperties.$currency = {
  currency
}// ?自訂全域屬性

app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(VueSweetalert2)

// ?排test來源
if (window.location.href.includes('test')) {
  app.use(VueGtag, {
    property: [
      { id: 'G-EYL9K9300Y' }, // *測試用
      { id: 'UA-213842897-1' }, // *測試用
      { id: 'UA-227888165-1' } // *papaWash正式GA追蹤碼(2023/7停用)
    ]
  })
} else {
  app.use(VueGtag, {
    property: [
      { id: 'G-EYL9K9300Y' }, // *測試用
      { id: 'UA-213842897-1' }, // *測試用
      { id: 'G-V9WRM2KCEK' }, // *papaWash正式GA追蹤碼(新版)
      { id: 'UA-227888165-1' } // *papaWash正式GA追蹤碼(2023/7停用)
    ]
  })
}
app.component('Loading', VueLoading)
app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)

app.component('Swiper', Swiper)
app.component('SwiperSlide', SwiperSlide)

app.mount('#app')
