const eventlist = [
  {
    path: '/promoteeventlist',
    name: '優惠活動',
    component: () => import('../views/EventList/PromoteEventList.vue'),
    meta: {
      title: 'papawash 優惠活動'
    }
  },
  {
    path: '/neweventlist',
    name: '最新活動',
    component: () => import('../views/EventList/NewEventList.vue'),
    meta: {
      title: 'papawash 最新活動'
    }
  },
  {
    path: '/seasoneventlist',
    name: '季節限定',
    component: () => import('../views/EventList/SeasonEventList.vue'),
    meta: {
      title: 'papawash 季節限定'
    }
  },
  {
    path: '/birthdaygift/P220615000001',
    name: '2022生日禮',
    component: () => import('../views/EventList/BirthdayGift.vue'),
    meta: {
      title: '2022 papawash 生日禮'
    }
  },
  {
    path: '/birthdaygift/P230612000001',
    name: '2023生日禮',
    component: () => import('../views/EventList/2023BirthdayGift.vue'),
    meta: {
      title: '2023 papawash 生日禮'
    }
  },
  {
    path: '/birthdaygift/P231208000002',
    name: '2024生日禮',
    component: () => import('../views/EventList/2024BirthdayGift.vue'),
    meta: {
      title: '2024 papawash 生日禮'
    }
  },
  {
    path: '/birthdaygiftbonus',
    name: '會員生日禮點數',
    component: () => import('../views/EventList/BirthdayGiftBonus.vue'),
    meta: {
      title: 'papawash 會員生日禮點數'
    }
  }
]

export default eventlist
